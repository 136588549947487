import React, { createContext, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import {
  AuthHandlerResponse,
  postAuthSignup,
  postLoginUser,
  postLogout,
} from 'api/auth';
import { API_RESPONSE_STATUS, AuthState, AUTH_STATUS } from 'types/auth';
import { trackEvent } from 'services/analytics/mixpanel';
import Bugsnag, { addErrorMetaData } from 'services/bugsnag';
import { clearBrowserAuth, setToken, tokenExists } from 'utils/auth';
import { AUTH_TOKEN } from 'constants/auth';
import { setUserIdentity } from 'utils/analytics';
import { MIXPANEL_EVENT } from 'constants/analytics';
import { refreshAccessToken } from 'api';

interface AuthenticationContext {
  authState: AuthState;
  logout: () => Promise<void>;
  login: (email: string, password: string) => Promise<AuthHandlerResponse>;
  signup: (email: string, password: string) => Promise<AuthHandlerResponse>;
}

export const AuthContext = createContext<AuthenticationContext>(
  {} as AuthenticationContext
);

export const useAuth = (): AuthenticationContext => {
  return useContext(AuthContext);
};
// TODO: Improve implementation with useSWR for profile
export const AuthProvider: React.FC<any> = ({ children }) => {
  const router = useRouter();
  const [authState, setAuthState] = useState<AuthState>({
    status: AUTH_STATUS.LOADING,
  });

  useEffect(() => {
    if (!router.isReady) return;
    if (tokenExists(AUTH_TOKEN.REFRESH)) {
      if (authState.status !== AUTH_STATUS.AUTHENTICATED) {
        refreshToken();
      }
    } else {
      setLoadingStatus(AUTH_STATUS.UNAUTHENTICATED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  const setLoadingStatus = (status?: AUTH_STATUS) => {
    setAuthState((prev) => ({
      ...prev,
      status: (status as any) || AUTH_STATUS.LOADING,
    }));
  };

  const logout = async (clearLocalOnly = false) => {
    try {
      if (!clearLocalOnly && authState.status === AUTH_STATUS.AUTHENTICATED) {
        trackEvent(MIXPANEL_EVENT.LOG_OUT);
        await postLogout();
      }
    } catch (error: any) {
      Bugsnag.notify('postLogout error', addErrorMetaData('error', { error }));
    }
    setAuthState({ status: AUTH_STATUS.UNAUTHENTICATED });
    clearBrowserAuth();
  };

  const login = async (
    email: string,
    password: string
  ): Promise<AuthHandlerResponse> => {
    setLoadingStatus();
    return postLoginUser(email, password).then(handleAuthAPIResponse);
  };

  const signup = async (email: string, password: string): Promise<any> => {
    setLoadingStatus();

    return postAuthSignup(email, password)
      .then(handleAuthAPIResponse)
      .then((data) => {
        if (data.status === 'ok') {
          // TODO:
          // Tracking and sending data to google analytics
        }
      })
      .catch((error) => console.error(`postAuthSignup: ${error}`));

    // return postAuthSignup( email, password ).then( handleAuthAPIResponse )
  };

  const handleAuthAPIResponse = (
    authResponse: AuthHandlerResponse,
    oauth = ''
  ) => {
    const { status, message } = authResponse;
    if (status === API_RESPONSE_STATUS.ERROR) {
      console.log('Error: ', message);
      setAuthState((auth) => ({
        ...auth,
        status: AUTH_STATUS.ERROR,
        error: message,
      }));
      // Logout
      clearBrowserAuth();
    } else {
      const authData = authResponse.auth!;
      if (oauth === '') {
        setToken(AUTH_TOKEN.ACCESS, authData.idToken);
        setToken(AUTH_TOKEN.REFRESH, authData.refreshToken);
      }
      setAuthState({
        status: AUTH_STATUS.AUTHENTICATED,
        email: authData.email,
        uid: authData.localId,
      });
      setUserIdentity(authData);
    }
    return { status, message };
  };

  const refreshToken = async () => {
    const tokenRefreshed = await refreshAccessToken();
    if (tokenRefreshed) {
      setLoadingStatus(AUTH_STATUS.AUTHENTICATED);
    } else {
      logout();
    }
  };

  return (
    <AuthContext.Provider
      value={{
        authState,
        logout,
        login,
        signup,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
