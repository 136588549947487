// RootWrapper
import React from 'react';
import useWindowSize, { BREAKPOINTS } from 'hooks/useWindowSize';
import Head from 'next/head';

const RootWrapper: React.FC<any> = ({ children }) => {
  const { width } = useWindowSize();

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=0,shrink-to-fit=no"
        />
      </Head>
      <div
        style={{
          width: '100%',
          maxWidth: width < BREAKPOINTS.SM ? width : '100%',
        }}
      >
        {children}
      </div>
    </>
  );
};

export default RootWrapper;
