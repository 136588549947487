import { MIXPANEL_EVENT } from 'constants/analytics';
import mp, { Dict } from 'mixpanel-browser';
import Bugsnag, { addErrorMetaData } from '../bugsnag';

// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production
mp.init(process.env.NEXT_PUBLIC_MIXPANEL_API_KEY || '', {
  debug:
    process.env.NODE_ENV !== 'production' || process.env.IS_PREVIEW === 'true',
});

const analyticsErrorHandler = (cb: Function) => {
  try {
    cb();
  } catch (error) {
    Bugsnag.notify('MixPanel Error', addErrorMetaData('error', { error }));
  }
};

export const identify = (id: string) =>
  analyticsErrorHandler(() => mp.identify(id));

export const trackEvent = (name: MIXPANEL_EVENT, data?: Dict) =>
  analyticsErrorHandler(() => {
    mp.track(`${name}`, data);
    window.gtag('event', name, data);
  });

export const trackPage = (url: string) =>
  analyticsErrorHandler(() => {
    mp.track('User Visits Page', { url });
    window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS as string, {
      page_path: url,
    });
  });

export const setSuperProperties = (data: Dict) =>
  analyticsErrorHandler(() => mp.register(data));

export const setProfileProperty = (
  property: string,
  data: string | number | boolean
) => analyticsErrorHandler(() => mp.people.set_once({ [property]: data }));

export const setAlias = (newId: string, existingId?: string) =>
  analyticsErrorHandler(() => mp.alias(newId, existingId));

export default mp;
