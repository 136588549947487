import { NextSeoProps } from 'next-seo';

const DEFAULT_SEO: NextSeoProps = {
  titleTemplate: '%s | Trend',
  defaultTitle: 'Trend AI',
  description:
    'Trend connects brands with content creators in order to scale user-generated content across a variety of platforms while increasing their marketing ROI.',
  openGraph: {
    type: 'website',
    locale: 'en_IE',
    url: 'https://studio.trend.io/',
    site_name: 'Trend AI',
    description:
      'Trend connects brands with content creators in order to scale user-generated content across a variety of platforms while increasing their marketing ROI.',
  },
  twitter: {
    handle: '@trend_io',
    site: '@trend_io',
    cardType: 'summary_large_image',
  },
};

export { DEFAULT_SEO };
