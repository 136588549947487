import { trendFetch } from 'api';
import { MIXPANEL_EVENT } from 'constants/analytics';
import { trackEvent } from 'services/analytics/mixpanel';
import Bugsnag, { addErrorMetaData } from 'services/bugsnag';
import {
  API_RESPONSE_STATUS,
  APIHandlerResponse,
  FirebaseAuthResponse,
} from 'types/auth';
import { getCookie, standardizeErrorMessage } from 'utils/auth';

export interface AuthHandlerResponse extends APIHandlerResponse {
  auth?: FirebaseAuthResponse;
}
// eslint-disable-next-line valid-jsdoc
/** For Stape analytics UTM */
const getUrl = () => {
  let url = window.location.href;
  if (!url.toLowerCase().includes('gclid')) {
    const fpgClawCookie = getCookie('FPGCLAW');
    if (fpgClawCookie) {
      const gclid = fpgClawCookie.split('.')[2];
      const newUrl = new URL(url);
      newUrl.searchParams.append('gclid', gclid);
      url = newUrl.toString();
    }
  }
  return url;
};

export const postAuthSignup = async (
  email: string,
  password: string
): Promise<AuthHandlerResponse> => {
  const url = getUrl();
  return await trendFetch('/auth/basic/signup', {
    method: 'POST',
    body: JSON.stringify({ email, password, url }),
  })
    .then(async (res) => {
      const jsonResponse = await res.json();
      const { idToken } = jsonResponse;
      if (res.ok && idToken) {
        trackEvent(MIXPANEL_EVENT.SIGN_UP, { email });
        return {
          status: API_RESPONSE_STATUS.OK,
          message: 'success',
          auth: jsonResponse,
        };
      } else {
        if (res.status === 409) {
          return {
            status: API_RESPONSE_STATUS.ERROR,
            message: 'Email already registered, please login',
          };
        }
        const { message } = jsonResponse;
        const errorMessage = standardizeErrorMessage(
          message || 'Failed to sign up'
        );
        return { status: API_RESPONSE_STATUS.ERROR, message: errorMessage };
      }
    })
    .catch((err) => {
      Bugsnag.notify(
        'postAuthSignup Error',
        addErrorMetaData('error', { error: err })
      );
      return { status: API_RESPONSE_STATUS.ERROR, message: 'Failed To Signup' };
    });
};

export const postLoginUser = (
  email: string,
  password: string
): Promise<AuthHandlerResponse> => {
  return trendFetch('/auth/basic/login', {
    method: 'POST',
    body: JSON.stringify({ email, password }),
  })
    .then(async (res) => {
      const jsonResponse = await res.json();
      const { idToken } = jsonResponse;
      if (res.ok && idToken) {
        trackEvent(MIXPANEL_EVENT.LOGIN);
        return {
          status: API_RESPONSE_STATUS.OK,
          message: 'success',
          auth: jsonResponse,
        };
      } else {
        const { message } = jsonResponse;
        const errorMessage = standardizeErrorMessage(
          message || 'Failed to login'
        );
        return { status: API_RESPONSE_STATUS.ERROR, message: errorMessage };
      }
    })
    .catch((err) => {
      Bugsnag.notify(
        'postLoginUser Error',
        addErrorMetaData('error', { error: err })
      );
      return { status: API_RESPONSE_STATUS.ERROR, message: 'Failed to login' };
    });
};

export const postLogout = () => {
  return trendFetch('/auth/logout', {
    method: 'POST',
  });
};
