import { AUTH_TOKEN } from 'constants/auth';
import { GetServerSidePropsContext, PreviewData } from 'next';
import { ParsedUrlQuery } from 'querystring';
import { destroyToken, getToken, setToken, tokenExists } from 'utils/auth';
// import fetch from 'cross-fetch';

type TrendFetchRequest = (defaultAuthToken?: string) => Promise<Response>;

type SSRContext = GetServerSidePropsContext<ParsedUrlQuery, PreviewData>;
interface TrendFetchOptions extends RequestInit {
  context?: SSRContext;
}

export const trendFetch = async (
  endpoint: string,
  options?: TrendFetchOptions
) => {
  const { context, ...reqOptions } = options || {};
  if (
    tokenExists(AUTH_TOKEN.REFRESH, context) &&
    !tokenExists(AUTH_TOKEN.ACCESS, context)
  ) {
    await refreshAccessToken();
  }

  const fetchRequest: TrendFetchRequest = () =>
    fetch(`${process.env.NEXT_PUBLIC_TREND_API}${endpoint}`, {
      ...reqOptions,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getToken(AUTH_TOKEN.ACCESS, context)}`,
        ...(options?.headers || {}),
      },
    });
  return fetchRequest().then((response) => {
    if (response.status === 401) {
      return refreshAndRetryRequest(fetchRequest, response);
    }
    return response;
  });
};

let refreshPromise: Promise<any> | null = null;
export const refreshAccessToken = async (context?: SSRContext) => {
  try {
    // Check for existing refresh token request
    if (!refreshPromise) {
      refreshPromise = fetch(
        `https://securetoken.googleapis.com/v1/token?key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}`,
        {
          method: 'POST',
          body: JSON.stringify({
            grant_type: 'refresh_token',
            refresh_token: getToken(AUTH_TOKEN.REFRESH, context),
          }),
          headers: { 'Content-type': 'application/x-ww-form-urlencoded' },
        }
      ).then(async (res) => {
        const json = await res.json();
        if (!res.ok) {
          throw new Error(json);
        }
        return json;
      });
    }
    // Wait for refresh token request to finish
    // eslint-disable-next-line camelcase
    const { id_token, refresh_token } = await refreshPromise;

    // TODO: Update User?
    // TODO: Need to cause log out

    // Set new tokens
    setToken(AUTH_TOKEN.ACCESS, id_token);
    setToken(AUTH_TOKEN.REFRESH, refresh_token);
    refreshPromise = null;
    return true;
  } catch (error) {
    console.error('Could not refresh token');
    console.error(error);
    destroyToken(AUTH_TOKEN.ACCESS);
    destroyToken(AUTH_TOKEN.REFRESH);
    refreshPromise = null;
    return false;
  }
};

// Call refreshAccessToken and re-attempt request
export const refreshAndRetryRequest = async (
  initialRequest: TrendFetchRequest,
  initialResponse: Response
) => {
  try {
    await refreshAccessToken();
    return initialRequest();
  } catch (error) {
    console.error('Could not refresh token');
    console.error(error);
    refreshPromise = null;
    return initialResponse;
  }
};

export const trendSWRFetch = (key: string) =>
  trendFetch(key).then((r) => r.json());
