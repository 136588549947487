import { setProfileProperty, identify } from 'services/analytics/mixpanel';
import Bugsnag from 'services/bugsnag';
import { FirebaseAuthResponse } from 'types/auth';

/**
 * MixPanel Tracking
 */
export const setUserIdentity = (authResponse: FirebaseAuthResponse) => {
  try {
    const { localId: userId, email } = authResponse;
    identify(userId);
    setProfileProperty('email', email);
    Bugsnag.setUser(userId, email);
  } catch (error) {
    console.error('Failed to set user identity');
    console.error(error);
  }
};

export const removeNullValues = (record: Record<string, any>) =>
  Object.fromEntries(
    Object.entries(record).filter(([, value]) => value != null)
  );
